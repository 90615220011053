import React, { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { formatPrice } from 'schema/price.schema';

import { OrderInfoCtaProps, OrderInfoHeaderProps, OrderProps } from './order-info.props';
import { OrderLines } from 'types/order';
import withOnKeyDown from 'hoc/withOnKeyDown';

import { AccordionCustomCtaProps } from 'ui-kit/accordion/accordion.props';
import BirdiAccordion from 'ui-kit/accordion/accordion';
import { BlueCircleAlertIcon } from 'ui-kit/icons/alert/alert-icon';
import { BlueCircleHourglassIcon } from 'ui-kit/icons/hourglass-icon/hourglass-icon';
import { BlueCircleShippingIcon } from 'ui-kit/icons/shipping-icon/shipping-icon';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

const OrderTrackingLink = ({
    shipperMethod,
    shippingTrackingNumber
}: {
    shipperMethod: string;
    shippingTrackingNumber: string;
}) => {
    let link = '';

    if (shipperMethod.includes('UPS')) {
        link = `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${shippingTrackingNumber}`;
    }

    if (shipperMethod.includes('USPS')) {
        link = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${shippingTrackingNumber}`;
    }

    if (link !== '') {
        return (
            <a href={link} target="_blank" rel="noreferrer">
                {shippingTrackingNumber}
            </a>
        );
    }

    // If no link, then just return the tracking number as a string, but wrap
    // it in React fragments so that TypeScript does not get upset.
    return <>shippingTrackingNumber</>;
};

const OrderInfoCta = withOnKeyDown(
    ({ titleIcon, order, isToggled, onClick, onKeyDown, translation }: OrderInfoCtaProps) => {
        return (
            <div className="order-info-cta" onClick={onClick} role="button" tabIndex={0} onKeyDown={onKeyDown}>
                <Row className="no-gutters">
                    <Col xs={2} sm={1} className="order-status-icon-container">
                        {titleIcon}
                    </Col>
                    <Col xs={8} sm={10} className="d-flex align-items-center">
                        <Row className="w-100 no-gutters">
                            <Col xs={12} sm={6} className="">
                                <span className="birdi-accordion-toggle-title-wrapper">
                                    {translation('pages.orderHistory.order.title', { orderDate: order.orderDate })}
                                </span>
                            </Col>
                            <Col xs={12} sm={6} className="d-flex align-items-center">
                                <div className="order-number-container text-left text-sm-right w-100">
                                    <span className="order-number-label">
                                        {translation('pages.orderHistory.order.orderInfoCta.orderNumberLabel')}
                                    </span>
                                    <span>{order && order.orderInvoiceNumber}</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2} sm={1} className="d-flex align-items-center">
                        <ChevronIcon
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                            direction={isToggled ? 'up' : 'down'}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
);

const OrderInfoHeader = ({ titleIcon, order, translation }: OrderInfoHeaderProps) => {
    return (
        <div className="order-info-cta">
            <Row className="no-gutters">
                <Col xs={2} sm={1} className="order-status-icon-container">
                    {titleIcon}
                </Col>
                <Col xs={8} sm={10} className="d-flex align-items-center">
                    <Row className="w-100 no-gutters">
                        <Col xs={12} sm={6} className="">
                            <span className="birdi-accordion-toggle-title-wrapper">
                                {translation('pages.orderHistory.order.title', { orderDate: order.orderDate })}
                            </span>
                        </Col>
                        <Col xs={12} sm={6} className="d-flex align-items-center">
                            <div className="order-number-container text-left text-sm-right w-100">
                                <span className="order-number-label">
                                    {translation('pages.orderHistory.order.orderInfoCta.orderNumberLabel')}
                                </span>
                                <span>{order && order.orderInvoiceNumber}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

const OrderInfo = ({ order, onGetOrderLines, translation, accordion = false }: OrderProps) => {
    const renderOrderDetails = () => {
        return (
            <div className="order-history-order-info">
                <div className="order-details-section-header d-flex align-items-center mb-3">
                    <div className="blue-half-pill mr-1" style={{ height: '0.8rem', width: '0.8rem' }} />
                    <div className="order-details-section-title">
                        {translation('pages.orderHistory.order.orderDetails.title')}
                    </div>
                </div>
                <Row className="order-details-container">
                    <Col>
                        {order && order.shipDate && (
                            <div>
                                {translation('pages.orderHistory.order.orderDetails.shipped', {
                                    shipDate: order.shipDate
                                })}
                            </div>
                        )}
                        {order && order.shipperMethod && <div>{order.shipperMethod}</div>}
                        {order &&
                            order.shipperMethod &&
                            order.shippingTrackingNumber &&
                            order.shippingTrackingNumber.length > 7 && (
                                <div>
                                    {translation('pages.orderHistory.order.orderDetails.trackingNumber')}
                                    <br />
                                    <OrderTrackingLink
                                        shipperMethod={order.shipperMethod}
                                        shippingTrackingNumber={order.shippingTrackingNumber}
                                    />
                                </div>
                            )}
                        <div className="text-capitalize">
                            {translation('pages.orderHistory.order.orderDetails.status', {
                                orderStatusDesc: order.orderStatusDesc ? order.orderStatusDesc.toLowerCase() : ''
                            })}
                        </div>
                        <div className="order-total">
                            {translation('pages.orderHistory.order.orderDetails.orderTotal', {
                                orderAmount: formatPrice(order.orderAmount)
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderPrescriptionDetails = ({ lines, key }: { lines: OrderLines; key: string }) => {
        const renderRxCost = () => {
            switch (order.orderStatusDesc) {
                case 'CLOSED':
                case 'CANCELED': {
                    return translation('pages.orderHistory.order.prescriptionDetails.costNotApplicable');
                }
                default: {
                    return translation('pages.orderHistory.order.prescriptionDetails.cost', {
                        patientCopay: formatPrice(lines.patientCopay)
                    });
                }
            }
        };

        const transformDoctorName = (doctorName: string) => {
            if (!doctorName) return '';

            let names = doctorName.split(',');
            for (let i in names) {
                names[i] = names[i].trim();
            }
            names[0] = names[0] + ',';
            return names.join(' ').toLowerCase();
        };

        return (
            <div key={key} className="prescription-details">
                <div className="order-details-section-header d-flex align-items-center mb-3">
                    <div className="blue-half-pill mr-1" style={{ height: '0.8rem', width: '0.8rem' }} />
                    <div className="order-details-section-title">
                        {translation('pages.orderHistory.order.prescriptionDetails.title')}
                    </div>
                </div>
                <Row className="order-details-container">
                    <Col xs={5} lg={4}>
                        <div>{lines.dispensedProductName}</div>
                        <div>
                            {translation('pages.orderHistory.order.prescriptionDetails.quantity', {
                                fillQuantity: lines.fillQuantity
                            })}
                        </div>
                    </Col>
                    <Col xs={7} lg={8}>
                        <div>{renderRxCost()}</div>
                        <div className="text-capitalize">
                            {translation('pages.orderHistory.order.prescriptionDetails.prescriber', {
                                doctorName: lines.doctorName ? transformDoctorName(lines.doctorName) : ''
                            })}
                        </div>
                        <div>
                            {translation('pages.orderHistory.order.prescriptionDetails.rxNumber', {
                                rxNumber: lines.rxNumber
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderOrderInfoBody = () => {
        return (
            <Row className="no-gutters">
                <Col xs={0} md={1}></Col>
                <Col xs={12} md={10} className="order-details-toggle-container">
                    <Container fluid>
                        <Row>
                            <Col xs={12} lg={7}>
                                {order && order.orderLines && order.orderLines.length === 0 && (
                                    <>
                                        {translation(
                                            'pages.orderHistory.order.prescriptionDetails.messages.noProductInformationAvailable'
                                        )}
                                    </>
                                )}
                                {order && !order.orderLines && (
                                    <>
                                        {translation(
                                            'pages.orderHistory.order.prescriptionDetails.messages.retrievingProductInformation'
                                        )}
                                    </>
                                )}
                                {order &&
                                    order.orderLines &&
                                    order.orderLines
                                        .filter(function (rx) {
                                            return rx.orderLineStatusDesc !== 'CANCELED';
                                        })
                                        .map((rx, i) =>
                                            renderPrescriptionDetails({
                                                lines: rx,
                                                key: `prescription-details-${rx.rxNumber}-${i}`
                                            })
                                        )}
                            </Col>
                            <Col xs={12} lg={5}>
                                {renderOrderDetails()}
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xs={0} md={1}></Col>
            </Row>
        );
    };

    const handleOrderInfoCtaClick = ({ onClick, isToggled }: AccordionCustomCtaProps) => {
        if (onClick) onClick();
        if (onGetOrderLines && !isToggled) onGetOrderLines(order.epostOrderNum);
    };

    const orderStatusIcon = useMemo(() => {
        switch (order.orderStatusDesc) {
            case 'CLOSED':
            case 'CANCELED': {
                return <BlueCircleAlertIcon style={{ height: '2.5rem', width: '2.5rem' }} />;
            }
            case 'SHIPPED WITH RETURNED ITEM(S)':
            case 'SHIPPED WITH REPLACED ITEM(S)':
            case 'SHIPPED': {
                return <BlueCircleShippingIcon style={{ height: '2.5rem', width: '2.5rem' }} />;
            }
            default: {
                return <BlueCircleHourglassIcon style={{ height: '2.5rem', width: '2.5rem' }} />;
            }
        }
    }, [order]);

    if (accordion) {
        return (
            <BirdiAccordion.Toggle
                variant={'full-cta'}
                includeTitleIcon={false}
                toggleIcon={<ChevronIcon />}
                customCta={({ onClick, isToggled }: AccordionCustomCtaProps) => (
                    <OrderInfoCta
                        titleIcon={orderStatusIcon}
                        isToggled={isToggled}
                        onClick={() => handleOrderInfoCtaClick({ onClick, isToggled })}
                        order={order}
                        translation={translation}
                    />
                )}
            >
                {renderOrderInfoBody()}
            </BirdiAccordion.Toggle>
        );
    } else {
        return (
            <BirdiAccordion.Toggle
                variant={'full-cta'}
                includeTitleIcon={false}
                startToggled={true}
                customCta={() => (
                    <OrderInfoHeader titleIcon={orderStatusIcon} order={order} translation={translation} />
                )}
            >
                {renderOrderInfoBody()}
            </BirdiAccordion.Toggle>
        );
    }
};

export default OrderInfo;
