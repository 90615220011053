import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { Col, Row } from 'react-bootstrap';

import { accountStateSelector } from 'state/account/account.selectors';
import { accountFetchOrderHistoryRoutine, accountFetchOrderLinesRoutine } from 'state/account/account.routines';

import PageLayout from 'components/layouts/page/page.layout';
import OrderInfo from 'components/order-info/order-info';

import BackgroundImage from 'ui-kit/background-image/background-image';
import BirdiAccordion from 'ui-kit/accordion/accordion';
import PageSection from 'ui-kit/page-section/page-section';

import '../history/order-history.style.scss';

const OrderDetail = ({ data }: { data: GatsbyTypes.OrderDetailDataQuery }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const { orderHistory } = useSelector(accountStateSelector);
    const urlParams = new URLSearchParams(location.search);
    const orderId = urlParams.get('id');

    const orderDetail = useMemo(
        () =>
            orderHistory &&
            orderHistory?.find(function (order) {
                return order.epostOrderNum === orderId;
            }),
        [orderHistory, orderId]
    );

    // Fetch the order history after the inital render.
    //
    // TODO - Rather than fetch the last year of orders and filter for the order ID
    // we're interested in, it would be better to directly fetch a specific order.
    // Unfortunately, we don't have an API which can do that at the moment, but
    // we have requested this feature.
    //
    // There are two limitations to the approach below:
    // 1) Orders placed more than one year from today's date will not be returned.
    // 2) If the user has more than 100 orders that are more recent than the one
    // requested, the order will not be returned.
    useEffect(() => {
        dispatch(
            accountFetchOrderHistoryRoutine.trigger({
                from: moment().add(-1, 'year').format('MM.DD.YYYY'),
                to: moment().add(1, 'day').format('MM.DD.YYYY'),
                page: '1',
                pageSize: '100'
            })
        );
    }, [dispatch]);

    // Fetch the order lines once the order history is available.
    useEffect(() => {
        if (orderDetail?.epostOrderNum) {
            dispatch(
                accountFetchOrderLinesRoutine.trigger({
                    epostOrderNum: orderDetail.epostOrderNum
                })
            );
        }
    }, [dispatch, orderDetail?.epostOrderNum]);

    return (
        <PageLayout metaData={{ nodeTitle: 'Order History' }}>
            <BackgroundImage image={data.blueSkyBackground}>
                <PageSection className="px-0 px-md-2">
                    <Row>
                        <Col
                            lg={{
                                span: 10,
                                offset: 1
                            }}
                        >
                            <div className="order-history order-history-detail">
                                <Row>
                                    <Col
                                        xl={{
                                            span: 10,
                                            offset: 1
                                        }}
                                    >
                                        <div className="order-history-header d-flex flex-column align-items-center">
                                            <h5 className="eyebrow-text">{t('pages.orderHistory.eyebrowText')}</h5>
                                            <h2>{t('pages.orderHistory.title')}</h2>
                                            <div className="spacer" />
                                        </div>

                                        <BirdiAccordion className="order-history-accordion">
                                            <>
                                                {!orderHistory && (
                                                    <div className="d-flex justify-content-center mb-5">
                                                        <h5>
                                                            {t('pages.orderHistory.messages.retrievingOrderHistory')}
                                                        </h5>
                                                    </div>
                                                )}
                                                {orderHistory && orderDetail && (
                                                    <OrderInfo
                                                        order={orderDetail}
                                                        onGetOrderLines={() => null}
                                                        translation={t}
                                                        accordion={false}
                                                    />
                                                )}
                                                {orderHistory &&
                                                    (orderHistory.length === 0 || orderDetail === undefined) && (
                                                        <div className="d-flex justify-content-center mb-5">
                                                            <h5>{t('pages.orderHistory.messages.orderNotFound')}</h5>
                                                        </div>
                                                    )}
                                            </>
                                        </BirdiAccordion>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </PageSection>
            </BackgroundImage>
        </PageLayout>
    );
};

export default OrderDetail;

export const query = graphql`
    query OrderDetailData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/blue-sky.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
